import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';

import Slider from './slider';
import HotNews from './hotNews';
import CityProducts from './cityProducts';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        const { citySlider, cityClass } = content;

        return (
            <div className={styles.col_wbf}>
                <Chip
                    id="285154"
                    type="recommend"
                    title="城市首页轮播图"
                    groupName="正文"
                    content={citySlider}
                    translate="handlePitSliderData">
                    <Slider />
                </Chip>
                <div className={styles.space30} />
                <div>
                    <div className={styles.col_w1000}>
                        <HotNews content={content} />
                        <div className={styles.space30} />
                        <CityProducts content={{ cityClass }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
