import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';

class CityDynamics extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    static defaultProps = {
        content: {},
    };

    render() {
        const cityDynamic = this.props.content;

        const cityDynamics = cityDynamic.slice(0, 2);

        return (
            <div className={styles.col_m}>
                <div className={styles.title_m}>
                    <a href={'https://finance.ifeng.com/'} target="_blank" title="more" rel={rel}>
                        More
                    </a>
                </div>
                <div className={styles.space26} />
                {cityDynamics.map(item => (
                    <div className={styles.tp} key={item.id}>
                        <a href={formatUrl(item.url)} target="_blank" title="">
                            <img src={formatImage(item.thumbnails.image[0].url, 260, 183)} width="260" height="183" />
                        </a>
                        <div className={styles.tp_bj} />
                        <div className={styles.tp_zi}>
                            <a href={formatUrl(item.url)} target="_blank" title="">
                                {item.title}
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default errorBoundary(CityDynamics);
