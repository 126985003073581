import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';

import CityProWrapper from '../../../components/cityProWrapper';

class CityProducts extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        cityClass: PropTypes.array,
    };

    render() {
        const { cityClass } = this.props.content;

        return (
            <div className={styles.col_1000}>
                {/* 城市讲堂 */}
                <div>
                    <div className={styles.zkcp_title}>
                        <img
                            src="//x0.ifengimg.com/ucms/2021_29/3138F2ACAE20BEFD6E0BEF4578AB122EAA7F778E_size9_w1000_h29.jpg"
                            width="1000"
                            height="29"
                        />
                    </div>
                    <div className={styles.space31} />
                    <Chip
                        id="285153"
                        type="recommend"
                        title="城市首页"
                        groupName="城市讲堂"
                        content={cityClass}
                        translate="handlePitProductsData">
                        <CityProWrapper />
                    </Chip>
                    <div className={styles.space31} />
                </div>
            </div>
        );
    }
}

export default CityProducts;
