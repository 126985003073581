import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { formatUrl, formatImage } from '@ifeng-fe/public_method';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import Chip from 'Chip';

import CityDynamics from '../../cityDynamics';
import CityObserver from '../../cityObserver';

class HotNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        const { cityObservationNo, cityDynamic, cityHot } = content;

        const result = cityHot.filter(item => item.children);

        const newCityHot = cityHot.concat(result);

        const dailyNews = newCityHot.slice(0, 11);

        return (
            <div className={styles.col_w1000}>
                {/* 城市频道-城市观察 */}
                <Chip
                    id="288073"
                    type="recommend"
                    title="城市观察强插维护位"
                    groupName="城市-城市观察"
                    content={cityObservationNo}
                    translate="handlePitProductsData">
                    <CityObserver />
                </Chip>
                {/* 城市频道-动态 */}
                <Chip
                    id="285155"
                    type="recommend"
                    title="城市首页"
                    groupName="城市-动态"
                    content={cityDynamic}
                    translate="handlePitProductsData">
                    <CityDynamics />
                </Chip>
                {/* 城市频道-热点资讯 */}
                <div className={styles.col_right}>
                    <div className={styles.title_r}>
                        <a href="https://finance.ifeng.com/" target="_blank" title="more" rel={rel}>
                            More
                        </a>
                    </div>
                    <Chip
                        id="20003"
                        type="recommend"
                        title="热点资讯"
                        groupName="城市-热点资讯"
                        content={cityHot}
                        translate="handlePitProductsData">
                        <div className={styles.col_r_wrapper}>
                            <ul>
                                {dailyNews.map(item => (
                                    <li key={item.id}>
                                        <a href={formatUrl(item.url)} target="_blank">
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Chip>
                </div>
            </div>
        );
    }
}

export default errorBoundary(HotNews);
