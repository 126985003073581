import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
/**
 * 定义 Nav 组件
 */
class CityHeader extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const nav = (
            <ul>
                {content.map((item, index) => {
                    if (index === 0) {
                        return (
                            <li key={item.name}>
                                <a
                                    href={item.url}
                                    target="_blank"
                                    title={item.title}
                                    className={styles.active}
                                    rel={rel}>
                                    {item.title}
                                    <br />
                                    <span>{item.name}</span>
                                </a>
                            </li>
                        );
                    } else {
                        return (
                            <li key={item.name}>
                                <a href={item.url} target="_blank" title={item.title} rel={rel}>
                                    {item.title}
                                    <br />
                                    <span>{item.name}</span>
                                </a>
                            </li>
                        );
                    }
                })}
            </ul>
        );

        return (
            <div className={styles.menu}>
                <div className={styles.menu_w1000}>
                    <div className={styles.logo}>
                        <a href="https://city.ifeng.com/">
                            <img
                                src="//x0.ifengimg.com/ucms/2021_30/53A027B99810D470591188106EEF6ADC167B01D4_size15_w420_h80.png"
                                title="logo"
                                alt="凤凰网城市频道"
                                rel={rel}
                            />
                        </a>
                    </div>
                    <div className={styles.menu_r}>{nav}</div>
                </div>
            </div>
        );
    }
}

export default errorBoundary(CityHeader);
