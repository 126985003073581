import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { rel } from '@ifeng-fe/container_channel/common/client/utils/rel';
import ObNewsItem from '../../components/obNewsItem';

class CityObserver extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    static defaultProps = {
        content: {},
    };

    render() {
        const cityObservationNo = this.props.content;

        return (
            <div className={styles.col_left}>
                <div className={styles.title_left}>
                    <a href="https://finance.ifeng.com/loc/special/cyxc/" target="_blank" title="more" rel={rel}>
                        More
                    </a>
                </div>
                <div>
                    {cityObservationNo.slice(0, 4).map((item, index) => {
                        return <ObNewsItem key={index} cityObservation={cityObservationNo} index={index} />;
                    })}
                </div>
            </div>
        );
    }
}

export default errorBoundary(CityObserver);
