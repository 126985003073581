import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Header from './header';
import transform from 'chipDataTransform';
import Footer from './footer/index';
import BottmChannelInfo from './bottmChannelInfo';

/**
 * for this page
 */
import CityHeader from '../components/cityHeader';
import Content from './content';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    render() {
        /**
         * 组件分发数据
         */
        const { content } = this.props;

        return (
            <div className={styles.ip_col}>
                <Header content={content.nav} />
                <Chip id="260055" type="struct" title="城市频道首页" groupName="城市频道" content={content.cityNav}>
                    <CityHeader />
                </Chip>
                <Content content={content} />
                <div className={styles.space90} />
                <div className={styles.footcolumn}>
                        <Chip
                            id="220498"
                            type="static"
                            title="页尾频道介绍"
                            groupName="凤凰网国际智库"
                            content={content.bottmChannelInfo}>
                            <BottmChannelInfo />
                        </Chip>
                </div>
                <ChipEdit transform={transform} />
                <Chip id="20036" type="struct" title="全站通用页尾导航" groupName="底部" content={content.footer}>
                    <Footer />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(Layout);
